import { getAuthHost } from "../configs/env";

const authHost = getAuthHost();

//makr request t ohttps://auth-dev.korra.ai/editor-sso?editorDomain=korra.ai
export async function fetchSSOConfig({
  editorDomain
}: {
  editorDomain: string;
}) {
  try {
    const url = `${authHost}/editor-sso?editorDomain=${editorDomain}`;
    const response = await fetch(url);
    return response.json().then(data => ({
      clientId: data.clientId,
      audience: data.audience,
      domain: data.domain
    }));
  } catch (e) {
    console.error('Failed to fetch SSO config', e);
    return null;
  }
}

export async function getSSOAvailability({
  workspaceId,
  token,
  userId,
}: {
  workspaceId: string;
  token: string;
  userId: string;
}) {
  // /get-sso-availability
  const response = await fetch(
    `${getAuthHost()}/get-sso-availability?workspaceId=${workspaceId}&userId=${userId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
      mode: "cors",
    }
  ).then((res) => res.json());

  return !!response.data
}

export async function toggleSSO({
  workspaceId,
  aliasKey,
  token,
  enabled,
}: {
  workspaceId: string;
  enabled: boolean;
  aliasKey: string;
  token: string;
}) {
  await fetch(`${getAuthHost()}/toggle-portal-sso?workspaceId=${workspaceId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      workspaceId,
      aliasKey,
      enabled
    }),
    mode: "cors",
  });
}

export async function updateAuthorizedGroupAccess({
  aliasKey,
  token,
  allowedUserEmails,
  workspaceId,
  userId,
}: {
  aliasKey: string;
  token: string;
  allowedUserEmails: string[];
  workspaceId: string;
  userId: string;
}) {
  await fetch(`${getAuthHost()}/set-allowed-user-emails?workspaceId=${workspaceId}&userId=${userId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      aliasKey,
      workspaceId,
      allowedUserEmails,
    }),
    mode: "cors",
  });
}

export async function downloadAllowedUserEmailsCSV({
  workspaceId,
  token,
  userId,
  aliasKey,
}: {
  workspaceId: string;
  token: string;
  userId: string;
  aliasKey: string;
}) {
  const response = await fetch(
    `${getAuthHost()}/get-allowed-user-emails-csv?workspaceId=${workspaceId}&userId=${userId}&aliasKey=${aliasKey}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
    }
  );

  if (!response.ok) {
    throw new Error('Failed to download CSV');
  }

  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = `allowed_user_emails_${workspaceId}.csv`;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
}
